<template>
  <form @submit.prevent="save" class="w-m-content">
    <div class="w-m-header">
      <p class="font-14 no-margin no-select" style="color: #293e52">Intimação</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <div class="font-11">

        <date-input
            class="m-t"
            input-class="app-input"
            date-type="date"
            date-format="##/##/####"
            v-model.lazy="intimacao.dataIntimacao"
            simpleBorder
            label="Data Intimação"
        />

        <date-input
            class="m-t"
            input-class="app-input"
            date-type="date"
            date-format="##/##/####"
            v-model.lazy="intimacao.dataPrazo"
            simpleBorder
            label="Data Prazo"
        />

        <date-input
            class="m-t"
            input-class="app-input"
            date-type="date"
            date-format="##/##/####"
            v-model.lazy="intimacao.dataResposta"
            simpleBorder
            label="Data Resposta"
        />

        <erp-s-field class="m-t e-input-modern size1" label="Observações">
          <textarea class="full-width app-input input-textarea" rows="5" v-model="intimacao.observacao">
          </textarea>
        </erp-s-field>

      </div>
    </div>
    <div class="w-m-footer text-right footer-modern">
      <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
             :color="'white'"
             text-color="grey-8"
             class="b-radius-3px btn-white m-r-sm"/>
      <u-btn :loading="loading" type="submit" label="Salvar" no-caps
             :color="'primary'"
             class="b-radius-3px"/>
    </div>
  </form>
</template>

<script>
import {ErpSField} from 'uloc-vue-plugin-erp'
import PersonMixin from "@/components/pessoa/components/include/PersonMixin"
import {adicionarIntimacao, findIntimacao, updateIntimacao} from "@/domain/processos/services/produtividade"
import {datePtToEn} from '@/utils/date'
import DateInput from "@/reuse/input/Date";
import {date} from "uloc-vue";

export default {
  name: 'ProdutividadeIntimacaoWindow',
  mixins: [PersonMixin],
  components: {
    DateInput,
    ErpSField
  },
  props: ['options', 'router', 'etapaId', 'intimacaoId'],
  data () {
    return {
      loading: !!this.intimacaoId,
      success: false,
      status: '',
      fake: {
        terms: false
      },
      intimacao: {
        dataPrazo: null,
        dataResposta: null,
        dataIntimacao: null,
        observacao: null,
        produtividadeEtapa: null
      }
    }
  },
  created () {
    this.intimacao.produtividadeEtapa = this.etapaId
    this.load()
  },
  mounted() {},
  computed: {},
  methods: {
    load () {
      this.intimacaoId && findIntimacao(this.intimacaoId)
          .then(response => {
            this.intimacao = response.data
            this.intimacao.produtividadeEtapa = this.intimacao.produtividadeEtapa.id

            const nomesDatas = [
              'dataIntimacao',
              'dataPrazo',
              'dataResposta'
            ]
            for (const nomeData of nomesDatas) {
              if (!this.intimacao[nomeData]?.date) continue
              this.intimacao[nomeData] = date.formatDate(this.intimacao[nomeData].date, 'DD/MM/YYYY')
            }
          })
          .catch(error => {
            this.alertApiError(error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    save () {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.intimacao))
      if (data.dataIntimacao) {
        data.dataIntimacao = datePtToEn(data.dataIntimacao)
      }
      if (data.dataPrazo) {
        data.dataPrazo = datePtToEn(data.dataPrazo)
      }
      if (data.dataResposta) {
        data.dataResposta = datePtToEn(data.dataResposta)
      }
      data.produtividadeEtapa = this.etapaId
      const method = this.intimacaoId ? updateIntimacao(this.intimacaoId, data) : adicionarIntimacao(data)
      method
          .then(response => {
            this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
            this.success = true
            this.dg()
          })
          .catch(error => {
            this.alertApiError(error)
          })
          .finally(() => {
            this.loading = false
          })
    }
  }
}
</script>
