import {removePersonObject} from '@/domain/arrematante/services'
import {mockTelefone} from "@/components/pessoa/mock/telefone"
import {mockEmail} from "@/components/pessoa/mock/email"
import {date} from "uloc-vue"

export default {
    methods: {
        transformToView (p) {
           
            if (p.emails && p.emails.length) {
                p.emails = p.emails.map(e => {
                    return {
                        ...e,
                        purpose: e.purpose && e.purpose.id ? e.purpose.id : (e.purpose || null)
                    }
                })
            }

            if (p.phoneNumbers && p.phoneNumbers.length) {
                p.phoneNumbers = p.phoneNumbers.map(e => {
                    return {
                        ...e,
                        purpose: e.purpose && e.purpose.id ? e.purpose.id : (e.purpose || null)
                    }
                })
            }

            if (p.addresses && p.addresses.length) {
                p.addresses = p.addresses.map(e => {
                    return {
                        ...e,
                        purpose: e.purpose && e.purpose.id ? e.purpose.id : (e.purpose || null)
                    }
                })
            }
            if (p.hasOwnProperty('birthDate') && !!p.birthDate) {
                p.birthDate = date.formatDate(p.birthDate.date, 'YYYY-MM-DD')
            }
            return p
        },
        transformToSubmit (person) {
            let p = JSON.parse(JSON.stringify(person))

            if (p.emails && p.emails.length) {
                p.emails = p.emails.map(e => {
                    return {
                        ...e,
                        purpose: e.purpose && e.purpose.id ? e.purpose.id : (e.purpose || null)
                    }
                })
            }

            if (p.phoneNumbers && p.phoneNumbers.length) {
                p.phoneNumbers = p.phoneNumbers.map(e => {
                    return {
                        ...e,
                        purpose: e.purpose && e.purpose.id ? e.purpose.id : (e.purpose || null)
                    }
                })
            }

            if (p.addresses && p.addresses.length) {
                p.addresses = p.addresses.map(e => {
                    return {
                        ...e,
                        purpose: e.purpose && e.purpose.id ? e.purpose.id : (e.purpose || null)
                    }
                })
            }
            if (p.hasOwnProperty('birthDate'), !!p.birthDate) {
                p.birthDate = date.formatDate(p.birthDate.date, 'DD/MM/YYYY')
            }
            return p
        },
        removeItem(type, person, object) {
            removePersonObject(type, person, object).then(({data}) => {
                console.log(data)
            })
                .catch(error => {
                    console.log(error)
                    this.$nextTick(() => {
                        this.alertApiError(error)
                        // this.error = true
                    })
                })
        },
        adicionarTelefone() {
            this.pessoa.phoneNumbers.push(JSON.parse(JSON.stringify(mockTelefone)))
        },
        removeTelefone(telefone, syncRemove = true) {
            if (telefone.id) {
                syncRemove && this.removeItem('telefones', this.pessoa.id, telefone.id)
            }
            this.pessoa.phoneNumbers.splice(this.pessoa.phoneNumbers.indexOf(telefone), 1)
        },
        adicionarEmail() {
            this.pessoa.emails.push(JSON.parse(JSON.stringify(mockEmail)))
        },
        removeEmail(email, syncRemove = true) {

            if (email.id) {
                syncRemove && this.removeItem('emails', this.pessoa.id, email.id)
            }
            this.updatingEmail = true
            this.pessoa.emails.splice(this.pessoa.emails.indexOf(email), 1)
            setTimeout(() => {
                this.updatingEmail = false
            }, 30)
        },
    }
}
